.imagem-pagina-construcao {
    width: 50%; 
    margin-bottom: 2em;
}

.nome-pagina-construcao {
    font-size: 3em; 
    color: #4e4e4e;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .imagem-pagina-construcao {
        width: 100%;
    }
}